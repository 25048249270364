<template>
  <div>
    <Header></Header>
    <div class="lone">
      <div class="banner">
        <div class="main_left">
          <div
            class="nav"
            v-for="(item, index) in navarr[0][num]"
            :key="index"
            @click="router(index)"
          >
            <div class="flex_row">
              <div>{{ item }}</div>
              <div>></div>
            </div>
          </div>
        </div>
        <div class="main_right">
          <div class="manager_box">
            <div class="img_header">
              <img :src="encnimg[num].imgurl" alt="" />
            </div>
            <div class="fs30">{{us[num].title}}</div>

            <div class="mating_img">
              <img :src="us[num].imgurl" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Herder.vue";
import Footer from "../components/Footer.vue";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  components: { Header, Footer },
  data() {
    return {
      encnimg:[
        {
          imgurl:require('../img/headerimg.png')
        },
        {
          imgurl:require('../img/headerenimg.png')
        }
      ],
      us:[
        {
          title:"联系我们",
          imgurl:require('../img/中文.png')
        },
        {
          title:"Contact Us",
          imgurl:require('../img/英文.png')
        }
      ],
      navarr: [
        {
          0: ["联系我们"],

          1: ["Contact Us"],
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.manager_text {
  margin: 0.4rem auto;
  font-size: 0.17rem;
  line-height: 0.3rem;
  color: #666666;
  margin-bottom: 0.3rem;
}
.mating_img {
  img {
    width: 100%;
  }
}
.none {
  border: none !important;
}
.mat {
  margin: 0.3rem auto;
  border-bottom: 0.02rem solid #dad5ce;
}
.row {
  display: flex;
  width: 8rem;

  margin: 0.3rem auto;
}
.dis {
  width: 50%;
  display: flex;
  img {
    height: 0.3rem;
  }
  div {
    font-size: 0.17rem;
    line-height: 0.3rem;
    margin-left: 0.1rem;
  }
}
.manager_box {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
}
.mating_img {
  img {
    width: 100%;
  }
}
.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;
  img{
    width: 100%;
  }
}
.fs30 {
  margin: 0.8rem auto;
  font-size: 0.3rem;
  text-align: center;
}
.lone {
  width: 100%;
  background-color: #f3f3f4;
  overflow: hidden;
}
.flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0.65rem;
  padding-left: 0.5rem;
  padding-right: 0.2rem;
}
.nav {
  width: 2.7rem;
  height: 0.65rem;
  background: white;
  margin-bottom: 0.05rem;
}
.nav:hover {
  background-color: #a08e75;
  color: white;
}
.banner {
  width: 12.6rem;

  margin: 1rem auto;
  display: flex;
}
.main_left {
  width: 3.47rem;
  height: 100%;
  border-right: 0.02rem solid #dad5ce;
}
.main_right {
  flex: 1;
}
</style>